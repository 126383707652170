/* OfferDetail.scss */
:root {
  --PrimaryColor: hsl(26, 93%, 50%);
  --hoverColor: hsl(26, 90%, 42%);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 18%);
  --textColor: hsl(240, 1%, 48%);
  --greyBg: hsl(0, 0%, 96%);
  --greyText: rgb(190, 190, 190);
  --inputColor: hsl(330, 12%, 97%);
}

.offer-detail {
  padding: 20px;

  .offer-content {
    max-width: 1200px;
    margin: 8% auto;

    .offer-header {
      text-align: center;
      margin-bottom: 40px;
      text-align: left;

      h1 {
        font-size: 36px;
        color: var(--PrimaryColor);
        margin-bottom: 10px;

        span {
          color: var(--PrimaryColor);
        }
      }

      h2 {
        font-size: 28px;
        color: var(--blackColor);
        margin-bottom: 15px;
      }

      p {
        font-size: 16px;
        color: var(--textColor);
        line-height: 1.5;
      }
    }

    .offer-details {
      display: flex;
      gap: 20px;
      margin-top: 20px;

      img {
        width: 50%;
        border-radius: 10px;
      }

      .offer-info {
        flex: 1;

        h2 {
          font-size: 24px;
          color: var(--PrimaryColor);
          margin-bottom: 10px;
        }

        .price {
          font-size: 20px;
          color: var(--SecondaryColor);
          margin-bottom: 15px;
        }

        .view-btn {
          display: inline-block;
          padding: 10px 20px;
          background-color: var(--PrimaryColor);
          color: #fff;
          border: none;
          border-radius: 5px;
          font-size: 16px;
          cursor: pointer;
          transition: background-color 0.3s;

          &:hover {
            background-color: var(--SecondaryColor);
          }
        }

        ul {
          list-style: none;
          padding: 0;
          margin-top: 20px;

          .amenity {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            gap: 10px;

            .icon {
              font-size: 18px;
              color: var(--PrimaryColor);
            }

            span {
              font-size: 14px;
              color: var(--textColor);
            }
          }
          .icons {
            font-size: 18px;
            color: var(--blackColor);
            padding-top: 5px;
          }
        }
      }
    }

    /* Facilities Section */
    .facilities-section {
      margin-top: 40px;
      padding: 20px;
      background-color: #f9f9f9;
      border-radius: 10px;

      h2 {
        font-size: 24px;
        color: var(--blackColor);
        margin-bottom: 5px;
      }

      .subtitle {
        font-size: 16px;
        color: var(--grayColor);
        margin-bottom: 15px;
      }

      .facilities-categories {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 20px;

        .category {
          h3 {
            font-size: 18px;
            color: var(--blackColor);
            margin: 20px 0px;
          }

          ul {
            list-style: none;
            padding: 0;

            li {
              display: flex;
              align-items: center;
              margin-bottom: 8px;
              gap: 10px;

              .icon {
                font-size: 20px;
                color: var(--PrimaryColor);
              }

              span {
                font-size: 14px;
                color: var(--textColor);
              }
            }
          }
        }
      }

      .contentend{
        height: auto;
        padding: 2% 0;

        .firstRow{
            font-size: 1.5rem;
            font-weight: 500;
            text-align: center;
            margin-bottom: 2%;
        }
        .secondRow{
            margin-top: 1%;
            text-align: center;

            .icon{
                color: var(--PrimaryColor);
                height: 1.2rem;
            }

            .features{
                padding: 0 1.2rem;
            }
        }   
    }
    }
  }
}

