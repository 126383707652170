:root{
  --PrimaryColor: hsl(26,93%, 50%);
  --hoverColor: hsl(26,90%, 42%);
  --whiteColor: hsl(0,0%, 100%);
  --blackColor: hsl(0,0%, 18%);
  --textColor: hsl(240,1%, 48%);
  --whiteColor: hsl(26,0%, 93%);
  --greyBg: hsl(0,0%, 96%);
  --greyText: hsl(190,190, 190);
  --inputColor: hsl(330,12%, 97%);
}

.home {
  height: 80vh;
  background: linear-gradient(rgba(33, 33, 33, 0.522), rgba(33, 33, 33, 0.522)), url('./../../Assets/bg3.jpg');
  // background: linear-gradient(rgba(33, 33, 33, 0.522), rgba(33, 33, 33, 0.522)), url('./../../Assets/bgImg.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  position: relative;

  .secContainer {
    .homeText {
      text-align: center;

      .title {
        color: var(--whiteColor);
        font-size: 2rem;
        font-weight: 700;
        line-height: 2.5rem;
      }

      .subtitle {
        color: var(--whiteColor);
        opacity: .9;
        font-size: 13px;
        font-weight: 300;
        padding: 1rem 0;
        max-width: 70%;
        text-align: center;
        margin: auto;
      }

      .btn {
        padding: .6rem 1.5rem;;
      }
    }

    .homeCard {
      width: 80%;
      border-radius: 1rem;
      padding: 2rem;
      background: var(--whiteColor);
      position: absolute;
      gap: 1rem;
      justify-content: center;
      bottom: -20%;
      transform: translate(-50%);
      left: 5%;
      box-shadow: 0 2px 8px 4px rgba(178, 178, 178, 0.45);

      .locationDiv, .disDiv, .priceDiv {
        width: 100%;

        label {
          display: block;
          color: var(--textColor);
          font-weight: 500;
          font-size: 13px;
          padding-bottom: .5rem;
          text-align: center;
        }

        input {
          width: 100%;
          background: var(--inputColor);
          border-radius: 10px;
          border: none;
          padding: 10px;
          font-size: 13px;
          font-weight: 500;
          text-align: center;

          &::placeholder {
            font-size: 13px;
            opacity: .95;
            font-weight: 400;
          }

          &:focus {
            outline: none;
          }
        }
      }

      .btn {
        width: 100%;
        height: 70%;
        padding: 0rem 1.5rem;
        color: var(--whiteColor);
        font-weight: 600;
      }
    }
  }
}

@media screen and (min-width: 280px) {
  .home {
    .secContainer {
      .homeCard {
        bottom: -30%;
        // width: 90%;
        // display: grid;
        // grid-template-columns: repeat(2, 1fr);
        // gap: 1rem;

        .btn {
          transform: translateY(15px);
        }
      }
    }
  }
} 

@media screen and (min-width: 480px) {
  .home {
    .secContainer {
      .homeCard {
        bottom: -25%;
        width: 90%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;

        .btn {
          transform: translateY(15px);
        }
      }
    }
  }
}

@media screen and (min-width: 556px) {
  .home {
    .secContainer {
      .homeText {
        .title {
          font-size: 2.5rem;
        }
        .subtitle {
          font-size: 18px;
        }
      }
    }
  }
}

@media screen and (min-width: 720px) {
  .home {
    .secContainer {
      .homeCard {
        bottom: -20%;
        grid-template-columns: repeat(4, 1fr); // Handles 4 items (location, distance, price, and button)
      }
    }
  }
}

@media screen and (min-width: 1080px) {
  .home {
    .secContainer {
      .homeText {
        padding: 1rem 10rem;

        .title {
          font-size: 3.0rem;
          margin: auto;
          line-height: 4rem;
        }

        .subtitle {
          width: 70%;
        }
      }
    }
  }
}

.pulse{
  height: 150px;
  width: 150px;
  background-color: hsl(26,93%, 50%);
}

.pulse:before,
.pulse:after{
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: hsl(26,93%, 50%);
  border-radius: 50%;
  z-index: -1;
  opacity: 0.7;
}

.pulse:before{
  animation: pulse 2s ease-out infinite;
}

.pulse:after{
  animation: pulse 2s 1s ease-out infinite;
}

@keyframes pulse{
  100%{
    transform: scale(2.5);
    opacity: 0;
  }
}