:root{
  --PrimaryColor: hsl(26,93%, 50%);
  --hoverColor: hsl(26,90%, 42%);
  --whiteColor: hsl(0,0%, 100%);
  --blackColor: hsl(0,0%, 18%);
  --textColor: hsl(240,1%, 48%);
  --whiteColor: hsl(26,0%, 93%);
  --greyBg: hsl(0,0%, 96%);
  --greyText: hsl(190,190, 190);
  --inputColor: hsl(330,12%, 97%);
}

.popular {
    padding-top: 15rem;
  
    .secContainer {
      .secHeader {
        display: flex; 
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
  
        .textDiv {
          .secTitle {
            color: var(--blackColor);
            line-height: 1.5rem;
            padding-bottom: .5rem;
          }
  
          p {
            font-size: 15px;
            color: var(--textColor);
            font-weight: 500;
          }
        }
  
        .iconsDiv {
          display: flex;
          gap: 5rem;
          .icon {
            padding: .5rem;
            background: var(--greyText);
            border-radius: 50%;
            font-size: 2.5rem;
            &:hover {
              background: var(--blackColor);
              color: var(--whiteColor);
            }
          }
  
          .lefticon {
            background: var(--blackColor);
            color: var(--whiteColor);
            &:hover {
              background: var(--greyText);
              color: var(--blackColor);
            }
          }
        }
      }
  
      .mainContent {
        padding: 2rem 0;
        gap: 2rem;

  
        .singleDestination {
          overflow: hidden;
          border-radius: 1rem;
          box-shadow: 0 2px 8px 2px rgba(178, 178, 178, 0.334);
          position: relative;
          padding: 1rem;
          text-align: left;
          flex: 1 1 calc(100% - 2rem);
          max-width: calc(100% - 2rem);

  
          .destImage {
            margin: 0;
            padding: 0;
            height: 200px;
            width: 100%;
            position: relative;
            overflow: hidden;
  
            img {
              margin: 0;
              // margin-left: 0%;
              display: block;
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
  
            .overlayInfo {
              position: absolute;
              display: flex;
              align-items: flex-start;
              padding: 0; 
              justify-content: center;
              flex-direction: column;
              background: linear-gradient(
                rgba(0, 0, 0, 0.846),
                rgba(0, 0, 0, 0.759)
              );
              bottom: 0;
              width: 100%;
              height: 0;
              overflow: hidden;
              transition: height 0.3s ease padding 0.3s ease;
  
              h3 {
                color: var(--whiteColor);
                font-weight: 600;
              }
              p {
                color: var(--whiteColor);
                opacity: 0.7;
                font-size: 13px;
                text-align: left;
              }
  
              .icon {
                position: absolute;
                right: 10px;
                bottom: 10px;
                padding: 0.5rem;
                background: var(--PrimaryColor);
                font-size: 2.5rem;
                border-radius: 50%;
                color: var(--whiteColor);
                border: 1px solid transparent;
                transition: 0.3s ease;
  
                &:hover {
                  background: var(--blackColor);
                  negative: var(--blackColor);
                  border: 1px solid var(--PrimaryColor);
                  color: var(--PrimaryColor);
                }
              }
            }
          }
  
          .desFooter {
            padding: 1rem;
  
            .number {
              font-size: 1.5rem;
              font-weight: 700;
              color: var(--PrimaryColor);
              position: relative;
              width: max-content;
              padding-right: 0.3rem;
              display: flex;
  
              // &::after {
              //   content: "";
              //   position: absolute;
              //   background: var(--PrimaryColor);
              //   top: 45%;
              //   right: -150%;
              //   height: 5px;
              //   width: 45px;
              //   border-radius: 1rem;
              // }
            }
  
            .desText {
              justify-content: space-between;
              display: grid;
  
              h6 {
                color: var(--blackColor);
                font-size: 1rem;
              }
              .dot{
                font-size: 0.75rem;
              }
  
              .flex {
                font-size: 0.5rem;
                font-weight: 600;
                align-items: flex-start;
                transform: translateY(5px);
  
                .icon {
                  color: var(--PrimaryColor);
                }

              }
            }
          }
  
          &:hover {
            .overlayInfo {
              height: 100%;
              padding: 1rem 1.5rem;
              height: 100%;
            }
          }
        }
      }
  
      .custom-arrow {
        position: absolute;
        top: -10%; 
        z-index: 1;
        width: 40px;           
        height: 40px;
        border-radius: 50%;   
        cursor: pointer;
  
        &.left {
          right: 60px; 
          background: var(--PrimaryColor);
          color: var(--whiteColor);
        }
  
        &.right {
          right: 10px; 
          background: var(--greyText);
          color: var(--blackColor);
        }
  
        .icon {
          padding: 0.5rem;
          background: inherit; 
          border-radius: 50%;
          font-size: 2.5rem;
  
          &:hover {
            background: var(--blackColor);
            color: var(--whiteColor);
          }
        }
      }

      @media (max-width: 568px) {
        .custom-arrow {
          display: none;
        }
      }
    }
  }

  @media screen and (min-width: 480px) {
    .popular{
      padding-top: 10rem ;

    }
  }
  @media screen and (min-width: 556px) {
    .popular{
      .secContainer{
        .secHeader{
          width: 100%;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .textDiv{
             text-align: start;
             max-width: 70%;
          }

          .icon{
            justify-content: flex-end;
          }
        }
      }
      
    }
  }
  

  @media screen and (min-width: 720px) {
    .popular{
      padding-top: 7rem;
      
    }
  }

  @media screen and (min-width: 1024px) {
    .popular{
      padding-top: 18rem;

      .secContainer{
        .mainContent{
          .singleDestination{
            gap: 1rem;
            
          }
        }
      }
    }
  }