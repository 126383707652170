:root{
    --PrimaryColor: hsl(26,93%, 50%);
    --hoverColor: hsl(26,90%, 42%);
    --whiteColor: hsl(0,0%, 100%);
    --blackColor: hsl(0,0%, 18%);
    --textColor: hsl(240,1%, 48%);
    --whiteColor: hsl(26,0%, 93%);
    --greyBg: hsl(0,0%, 96%);
    --greyText: hsl(190,190, 190);
    --inputColor: hsl(330,12%, 97%);
  }

  .footer{
    background: var(--greyBg);
    padding: 2rem 0;

    .secContainer{
        gap: 1.5rem;
        text-align: center;
        .footerLogo{
            padding-bottom: .5rem;
            .log{
                color: var(--blackColor);
                font-weight: 500;
                cursor: pointer;
                align-items: center;
                justify-content: center;

                a {
                    color: var(--blackColor); 
                    text-decoration: none; 
    
                    &:hover {
                        text-decoration: underline;
                    }
                }

            .icon{
                font-size: 25px;
                color: var(--PrimaryColor);
                margin-right: .5rem;
            }
            .logoName{
                color: var(--blackColor);
            }

            }

        }

        .socilas{
            gap: .5rem;
            margin: auto;
            justify-content: center;

            .icon{
                font-size: 30px;
                background: var(--PrimaryColor);
                border-radius: 50%;
                padding: 8px;
                color: var(--whiteColor);
                &:hover{
                    background: var(--blackColor);
                }
            }
        }

        .footerLinks{
            display: grid;
            row-gap: .2rem;
            .linkTitle{
                padding-bottom: .5rem;
                color: var(--blackColor);
                font-weight: 700;
            }

            li{
                &:hover{
                    transform: translateX(10px);
                    a{
                        color: var(--PrimaryColor);
                    }
                }
            }

            a, .phone, .email{
                color: var(--textColor);
                font-size: 13px;
                font-weight: 500;
            }
        }
    }
  }


  @media screen and (min-width: 720px) {
    .secContainer{
        grid-template-columns: repeat(2, 1fr);
        text-align: center;
        align-items: flex-start;

        .footerLinks{
            justify-content: center;
        }
    }
  }

  @media screen and (min-width: 640px) {
    .secContainer{
        grid-template-columns: repeat(4, 1fr);
    }
  }

  @media screen and (min-width: 900px) {
    .secContainer{
        padding: 2rem;
    }
  }