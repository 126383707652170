
:root{
    --PrimaryColor: hsl(26,93%, 50%);
    --hoverColor: hsl(26,90%, 42%);
    --whiteColor: hsl(0,0%, 100%);
    --blackColor: hsl(0,0%, 18%);
    --textColor: hsl(240,1%, 48%);
    --whiteColor: hsl(26,0%, 93%);
    --greyBg: hsl(0,0%, 96%);
    --greyText: hsl(190,190, 190);
    --inputColor: hsl(330,12%, 97%);
  }
   
.offer{
    .secContainer{
        .mainContent{
            gap: 1rem;
            // grid-template-columns: repeat(3, minmax(300px, 1fr));


            .singleOffer{
                border-radius: 1rem;
                overflow: hidden;
                padding: 1rem;
                box-shadow: 0 2px 8px 2px rgba(178,178,178,0.189);
                transition: transform 0.3s ease;

                &:hover {
                    transform: translateY(-7px); 
                  }
          

                .icon{
                    font-size: 28px;
                    color: var(--blackColor);
                }

                small{
                    font-weight: 500;
                    color: var(--textColor);
                }

                .destImage{
                    position: relative;
                    height: 200px;
                    width: 100%;
                    border-radius: 1rem;
                    overflow: hidden;

                    img{
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }

                    .discount{
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        padding: 5px 10px;
                        background: var(--PrimaryColor);
                        color: var(--whiteColor);
                        border-radius: 3rem;
                        font-size: 10px;
                        font-weight: 500;
                    }
                }

                .offerBody{
                    padding: 1rem;

                    .price{
                        justify-content: space-between;
                        display: flex;
                        height: 3rem;

                        h4{
                            text-align: left;
                            color: var(--blackColor);
                            font-weight: 600;
                            font-size: 1.05rem;

                            .cardId{
                                color: var(--PrimaryColor);
                            }

                            .firstWord {
                                text-decoration: underline var(--PrimaryColor);
                                color: var(--PrimaryColor);
                              }
                        }

                        .status{
                            padding: 5px 10px;
                            color: var(--HoverColor);
                            background: var(--greyBg);
                            font-size: 10px;
                            font-weight: 600;
                            border-radius: 3rem;
                        }
                    }

                   .amenities{
                        width: 100%;
                        flex-wrap: wrap;
                        justify-content: flex-start;
                        padding: 1rem 0;
                        gap: .5rem;


                        .singleAmenity{
                            padding: 5px 10px;
                            border-radius: 5px;
                            background: var(--greyBg);
                            gap: .5rem;

                            .icon{
                                color: var(--blackColor);
                                height: 1.1rem;
                            }
                        }
                   } 

                   .location{

                    .icon{
                        color: var(--blackColor);
                        padding-right: 0.5rem;
                    }
                   }

                   .btn{
                    margin-top: 1rem;
                    width: 100%;
                    text-align: center;
                    justify-content: center;
                    padding: 0.6rem;
                    color: var(--whiteColor);
                    font-weight: 600;
                    gap: 0.5rem;
                    display: flex; /* Ensure the button content is treated as a flex container */
                    align-items: center; /* Align items vertically in the center */
                    background: var(--PrimaryColor); /* Add a background color for visibility */
                    border-radius: 5rem; /* Keep the button rounded like your image */
                    border: none; /* Remove default button borders */
                    cursor: pointer;

                    &:hover {
                        background: var(--hoverColor); /* Change background color on hover */
                      }
                   }

                   .btn a {
                    display: flex; /* Flexbox for link contents */
                    align-items: center; /* Align items vertically */
                    text-decoration: none; /* Remove underline */
                    color: inherit; /* Inherit color from parent */
                    gap: 0.5rem; /* Add spacing between text and icon */
                  }

                   .icon{
                    font-size: 1.2rem;
                    color: var(--whiteColor);
                   }
                }

                &:hover{
                    transform: translateY(-7px);
                }
            }
        }

        .contentend{
            height: auto;
            padding: 5% 0;

            .firstRow{
                font-size: 1.5rem;
                font-weight: 500;
                text-align: center;
            }
            .secondRow{
                margin-top: 2%;
                text-align: center;

                .icon{
                    color: var(--PrimaryColor);
                    height: 1.2rem;
                }

                .features{
                    padding: 0 1.2rem;
                }
            }   
        }
    }
}

