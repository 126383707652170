:root {
    --PrimaryColor: hsl(26, 93%, 50%);
    --hoverColor: hsl(26, 90%, 42%);
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(0, 0%, 18%);
    --textColor: hsl(240, 1%, 48%);
    --greyBg: hsl(0, 0%, 96%);
    --greyText: hsl(190, 190%, 190%);
    --inputColor: hsl(330, 12%, 97%);
}

.about {
    .secContainer {
        .title {
            color: var(--blackColor);
            line-height: 1.5rem;
            padding-bottom: 2rem;
            text-align: center;
            font-size: 2.2rem;
        }

        .mainContent {
            display: grid; 
            grid-template-columns: repeat(3, 1fr); 
            gap: 3rem; 
            align-items: center;
            justify-content: center;
            margin-top: 2rem;

            .singleItem {
                text-align: center;
                margin-bottom: 2.5rem;

                .icon {
                    font-size: 2rem;
                    color: var(--PrimaryColor); /* Use the primary color for icons */
                    margin-bottom: 0rem; /* Add spacing below the icon */
                  }

                .imgIcon {
                    width: 100px;
                    padding: 1rem;
                    color: var(--inputColor);
                }

                h3 {
                    font-weight: 700;
                    font-size: 1.5rem;
                    color: var(--blackColor);
                    padding: .5rem;
                }

                p {
                    font-size: 1rem;
                    font-weight: 500;
                    padding-bottom: .5rem;
                    color: var(--textColor);
                    text-align: center;
                }
            }
        }
    }
}

@media screen and (max-width: 720px) { 
    .about {
        .secContainer {
            .title{
                font-size: 1.5rem;
            }
            .mainContent {
                grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); 

                .singleItem {

                }
            }
        }
    }
}
